<template>
  <div class="ui-skeleton" />
</template>

<style scoped>
@keyframes bg-animate {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -150% 0;
  }
}

.ui-skeleton {
  background-image: linear-gradient(
    to right,
    #F4F4F7 0%,
    #F8F9FB 10%,
    #F4F4F7 20%,
    #F4F4F7 100%
  );
  background-size: 200% 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%),
  0 2px 4px -2px rgb(0 0 0 / 10%);
  animation: bg-animate 1.2s linear infinite;
}
</style>
