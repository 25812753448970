import type {
  Fit,
  ImageFormat,
} from '../types/nuxt-responsive-picture';

const IMAGE_DEFAULT = {
  ASPECT_RATIO: '1/1',
  QUALITY: 85,
  TRANSPARENT: 'transparent',
};

const OBJECT_FIT = {
  CONTAIN: 'contain',
  COVER: 'cover',
  FILL: 'fill',
  NONE: 'none',
};

const IMAGE_FORMAT: Record<string, ImageFormat | 'auto'> = {
  _AUTO: 'auto',
  AVIF: 'avif',
  GIF: 'gif',
  JPEG: 'jpeg',
  JPG: 'jpg',
  PNG: 'png',
  SVG: 'svg',
};

const IMAGE_FIT: Record<string, Fit | ''> = {
  _UNDEFINED: '',
  CONTAIN: 'contain',
  COVER: 'cover',
  FILL: 'fill',
  IN: 'in',
  INSIDE: 'inside',
  OUTSIDE: 'outside',
};

const DEFAULT_BACKGROUND_COLOR = { b: 'FFFFFF' };

export {
  DEFAULT_BACKGROUND_COLOR,
  IMAGE_DEFAULT,
  IMAGE_FIT,
  IMAGE_FORMAT,
  OBJECT_FIT,
};
